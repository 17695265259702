<template>
    <div class="test-app-datetime-picker">
        <div class="container">
            <div class="row">
                <div class="col-12 col-tab-6 col-mob-12">
                    <div>datetime_nanos: {{ datetime_nanos }}</div>
                    <div>date: {{ date }}</div>
                    <div>time: {{ time }}</div>
                </div>

                <div class="col-4 col-tab-6 col-mob-12">
                    <app-input-datepicker v-model="date" label="Date"></app-input-datepicker>
                    <app-input-timepicker v-model="time" label="HH:mm" :disabled="!date"></app-input-timepicker>
                </div>

                <div class="col-4 col-tab-6 col-mob-12">
                    <app-input-datetime-picker v-model="datetime_nanos" label="Date Time Picker" label-date="Date" label-time="Time"></app-input-datetime-picker>
                    <app-input-datetime-picker v-model="datetime_nanos" label="Date Time Picker" label-date="Date" label-time="Time"></app-input-datetime-picker>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appInputDatepicker from '@/components/app-input-datepicker'
import appInputTimepicker from '@/components/app-input-timepicker'

import appInputDatetimePicker from '@/components/app-input-datetime-picker'

export default {
    components: {
        appInputDatepicker,
        appInputTimepicker,

        appInputDatetimePicker,
    },

    data() {
        return {
            // datetime_nanos: 1593399304009803300,
            datetime_nanos: null,
        }
    },

    computed: {
        date: {
            get() {
                return this.datetime_nanos ? (
                    this.datetime_nanos instanceof Date
                        ? this.datetime_nanos
                        : new Date(this.datetime_nanos / 1000000)
                    ) : this.datetime_nanos
            },
            set(value) {
                console.group('SET date')
                console.log('value', value)

                if (!this.date) {
                    value.setHours(0, 0, 0, 0)
                }

                this.datetime_nanos = value
                console.log('this.datetime_nanos', this.datetime_nanos)

                console.groupEnd()
            },
        },

        time: {
            get() {
                let [ HH, mm ] = this.date
                    ? this.date.toLocaleTimeString("hi-IN", {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }).split(':')
                    : [null, null]

                if (HH > 23) { HH = "00" }

                return { HH, mm }
            },
            set({ HH, mm }) {
                console.group('SET time')
                console.log('{ HH, mm }', { HH, mm })

                if (this.date) {
                    let date = new Date(this.date.getTime())
                        date.setHours(HH, mm, 0, 0)

                    this.datetime_nanos = date
                }

                console.log('this.datetime_nanos', this.datetime_nanos)

                console.groupEnd()
            },
        },
    },
}
</script>

<style lang="scss">
.test-app-datetime-picker {
    padding: 80px 0 360px;

    .row {
        &>div {
            margin-bottom: 24px;

            &>* {
                margin-bottom: 24px;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
</style>